<template>
  <div class="row">
    <div class="alert alert-primary d-flex align-items-center" role="alert">
        <div>
            Kein Inhalt gefunden...
        </div>
    </div>
  </div>
</template>
<script setup>
</script>